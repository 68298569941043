import { createTheme } from '@material-ui/core/styles'
export const customTheme = createTheme({
  palette: {
    primary: {
      main: '#197fdf',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#0080ff',
    },
  },
})
