import { useState } from 'react'
import axios from 'axios'
import { Grid, TextField, Button, CircularProgress } from '@material-ui/core'
import { useForm } from 'react-hook-form'

export default function HomePage() {
  const [loading, setIsLoading] = useState(false)

  const { register, handleSubmit, errors } = useForm()

  const submitHandler = async (data) => {
    setIsLoading(true)
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/register`, data)
    setIsLoading(false)
  }

  return (
    <Grid container justifyContent='center'>
      <Grid item md={4}>
        <h2
          style={{ color: '#197fdf', fontWeight: 'bold', marginBottom: '5px' }}
        >
          Registration
        </h2>
        <p style={{ align: 'right', marginTop: '0px' }}>
          Register here so that we can match you with suitable job sharing
          partners:
        </p>
        <TextField
          type='name'
          label='First Name'
          variant='outlined'
          name='firstName'
          {...register('firstName', { required: true })}
          fullWidth
          style={{ marginBottom: '10px' }}
        />
        <TextField
          type='name'
          label='Last Name'
          variant='outlined'
          name='lastName'
          {...register('lastName', { required: true })}
          fullWidth
          style={{ marginBottom: '10px' }}
        />
        <TextField
          type='email'
          label='Email'
          variant='outlined'
          name='email'
          {...register('email', { required: true })}
          style={{ marginBottom: '10px' }}
          fullWidth
        />
        <TextField
          label='Workplace'
          variant='outlined'
          name='workplace'
          {...register('workplace', { required: true })}
          style={{ marginBottom: '10px' }}
          fullWidth
        />
        <TextField
          label='Position'
          variant='outlined'
          name='position'
          {...register('position', { required: true })}
          style={{ marginBottom: '10px' }}
          fullWidth
        />

        <TextField
          label='Progress'
          variant='outlined'
          name='progress'
          {...register('progress', { required: true })}
          style={{ marginBottom: '10px' }}
          fullWidth
        />

        <TextField
          label='Area Of Expertise'
          variant='outlined'
          name='areaOfExpertise'
          {...register('areaOfExpertise', { required: true })}
          style={{ marginBottom: '10px' }}
          fullWidth
        />
        <Grid
          container
          justifyContent='space-between'
          style={{ marginTop: '25px' }}
        >
          <Grid item></Grid>
          <Grid item>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit(submitHandler)}
              >
                Submit
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
