import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from './HomePage'
import { customTheme } from './utils/customTheme'
import { ThemeProvider } from '@material-ui/styles'

function App() {
  return (
    <Router>
      <ThemeProvider theme={customTheme}>
        <Switch>
          <Route path='/' exact component={HomePage} />
        </Switch>
      </ThemeProvider>
    </Router>
  )
}

export default App
